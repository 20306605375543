import * as React from "react";
import { HtmlHead, Link } from "@cdv/jazz-web/components";
import { Typography, Box, Container, Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";

export const Head = () => (
  <HtmlHead title="O problému | Centrum dopravního výzkumu, v. v. i.">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="O problému" lang="cs" />
    <meta name="keywords" content="O, problému" lang="cs" />
    <meta name="robots" content="index, follow" />
    <meta name="rating" content="general" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </HtmlHead>
);

export default function Page() {
  return (
    <Layout>
      <Container>
        <Typography variant="body1">
          Neoprávněné vstupy do prostoru dráhy jsou v České republice velmi frekventovaným a do
          značné míry i společensky přijatelným jednáním, ačkoliv se jedná o chování vysoce rizikové
          a zároveň nelegální (porušení zákona č. 266/1994 Sb., o dráhách, § 4a o ochraně dráhy).
          Každoročně se v důsledku tzv. trespassingu odehraje okolo 250 střetů osob s drážními
          vozidly, které končí až v 80 % případů smrtí. Nezanedbatelné jsou i následky materiální
          v souvislosti se zpožděním vlaků a škodami na vozidlech nebo infrastruktuře, střet také
          znamená značný stres pro strojvedoucího. Škody působí i samotný pohyb osob po železničním
          svršku; je-li v určitém místě trespassing intenzívní, může například docházet
          k nebezpečnému sešlapání štěrkového lože.
        </Typography>

        <Typography variant="body1">
          Společným jmenovatelem nejvíce frekventovaných ilegálních zkratek je přerušení přirozených
          pěších vazeb bariérou dráhy. Tyto vazby buď již existovaly v době, kdy se železnice
          stavěla, častěji pak, když železnice usnadnila rozmach daného města, postupně vznikaly
          v souvislosti s odlišným využitím území po obou stranách dráhy (typicky např. rozvoj
          průmyslu, pracovních příležitostí na jedné, bydlení na druhé), aniž by byly systematicky
          zajišťovány možnosti legálního přecházení. Kromě těchto každodenních zkratek můžeme najít
          intenzívní trespassing také na nádražích a zastávkách, kde není dobře vyřešeno přecházení
          a přístup k vlakům, pohyb osob v profilu dráhy se často vyskytuje i v rekreačních
          oblastech v souvislosti s turistickými a volnočasovými aktivitami, někdy jsou dráha nebo
          drážní stavby přímo cílem různých zájmových skupin, pro určité sociální skupiny i domovem.
          Samostatnou kapitolu tvoří železniční přejezdy – k neoprávněným vstupům chodců a cyklistů
          dochází v době, kdy jsou ve výstraze.
        </Typography>

        <Typography variant="body1">
          Problém neoprávněných vstupů do prostoru dráhy není neřešitelný a v současné době již
          existuje řada nástrojů, kterými lze trespassing v konkrétních místech omezit nebo mu zcela
          zabránit. V každém případě je žádoucí přizvat odborníky a využít standardizovaného
          postupu, pomocí kterého určíme nejen typ a příčiny trespassingu, ale i přiměřená opatření
          a jejich finanční náročnost. Ta mohou být vzdělávací, technologická nebo stavební,
          zahrnovat terénní úpravy, jako doprovodné opatření se osvědčil policejní dozor; vždy je
          optimální použít kombinaci opatření. Stavební opatření (podchody, nadchody, lávky apod.)
          jsou nejnáročnější, ale zároveň nejúčinnější. Technologická opatření, která zpravidla mají
          za cíl omezit přístup na dráhu v místě, které k tomu není určené, jsou méně nákladná,
          jejich účinnost je však podmíněna dostupností legální alternativy. Příkladem může být
          zařízení pro varování chodců a cyklistů před vstupem do nebezpečného pásma dráhy, které ve
          spolupráci s CDV vyvinula společnost AK signal Brno, a.s. a výsledky testování účinnosti
          jsou zatím velmi slibné.{" "}
        </Typography>

        <Typography variant="body1">
          Různorodou skupinou jsou opatření vzdělávací a osvětová, která mohou zahrnovat celostátní
          kampaně i lokální vzdělávací akce, nebo zapracování otázek bezpečného pohybu v blízkosti
          dráhy do školní výuky. Ačkoliv tato opatření mají v prevenci trespassingu nezastupitelnou
          úlohu, jejich účinnost je krátkodobá a zároveň je při jejich realizaci třeba opatrnosti,
          aby nebyla kontraproduktivní. Patří sem i problematika zveřejňování informací o nehodách
          v médiích – s ohledem na etický přístup, ale i riziko imitovaných sebevražd je dobré
          aplikovat doporučení WHO z roku 2008.{" "}
        </Typography>

        <Typography variant="body1">&nbsp;</Typography>

        <Box>
          <Box>
            <Grid container spacing={8}>
              <Grid item md={3}>
                <StaticImage src="../images/obrazek-4.png" alt="obrázek-4" />
              </Grid>
              <Grid item md={3}>
                <StaticImage src="../images/obrazek-5.png" alt="obrázek-5" />
              </Grid>
              <Grid item md={3}>
                <StaticImage src="../images/obrazek-2.png" alt="obrázek-2" />
              </Grid>
              <Grid item md={3}>
                <StaticImage src="../images/obrazek-3.png" alt="obrázek-3" />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
}
